/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {LegacyAddress as Address} from '@signatu/common-react';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    a: "a",
    div: "div",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Signatu works with legal experts that quality review Signatu Privacy Policy clauses."), "\n", React.createElement(_components.h3, {
    id: "want-to-become-a-partner",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#want-to-become-a-partner",
    "aria-label": "want to become a partner permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Want to become a partner?"), "\n", React.createElement(_components.p, null, "Send us an email at ", React.createElement(_components.a, {
    href: "mailto:hello@signatu.com"
  }, "mailto:hello@signatu.com")), "\n", React.createElement(_components.h2, {
    id: "legal-partner-network",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#legal-partner-network",
    "aria-label": "legal partner network permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Legal Partner Network"), "\n", React.createElement(Address, {
    name: "Bull & Co AS",
    line1: "Observatoriegt. 1B",
    line2: "PB 2583 Solli",
    city: "Oslo",
    zip: "0203",
    isoCountry: "no"
  }), "\n", React.createElement(_components.p, null, "☎️ +47 23 01 01 01"), "\n", React.createElement(_components.p, null, "🔗 ", React.createElement(_components.a, {
    href: "https://www.bull.no"
  }, "https://www.bull.no")), "\n", React.createElement(Address, {
    name: "Abreu Advogados",
    line1: "Av. Infante D. Henrique, 26",
    city: "Lisbon",
    zip: "1149-096",
    isoCountry: "pt"
  }), "\n", React.createElement(_components.p, null, "☎️ (+351) 21 723 18 00"), "\n", React.createElement(_components.p, null, "🔗 ", React.createElement(_components.a, {
    href: "https://www.abreuadvogados.com"
  }, "https://www.abreuadvogados.com")), "\n", React.createElement(Address, {
    name: "Zacco",
    line1: "Östra Hamngatan 5",
    line2: "P.O. Box 5581",
    city: "Stockholm",
    zip: "SE-114 85",
    isoCountry: "se"
  }), "\n", React.createElement(_components.p, null, "☎️ +46 31 725 81 00"), "\n", React.createElement(_components.p, null, "🔗 ", React.createElement(_components.a, {
    href: "https://www.zacco.com"
  }, "https://www.zacco.com")), "\n", React.createElement(Address, {
    name: "Meyerlustenberger Lachenal Ltd.",
    line1: "Schiffbaustrasse 2",
    line2: "Postfach 1765",
    city: "Zürich",
    zip: "8031",
    isoCountry: "ch"
  }), "\n", React.createElement(_components.p, null, "☎️ +41 44 396 91 91"), "\n", React.createElement(_components.p, null, "🔗 ", React.createElement(_components.a, {
    href: "https://www.mll-legal.com"
  }, "https://www.mll-legal.com")), "\n", React.createElement(Address, {
    name: "Data Privacy Office LLC",
    line1: "Fabriciusa str. 9A, office 204а",
    city: "Minsk",
    zip: "220007",
    isoCountry: "by"
  }), "\n", React.createElement(_components.p, null, "☎️ +375297577377"), "\n", React.createElement(_components.p, null, "🔗 ", React.createElement(_components.a, {
    href: "https://data-privacy-office.com/en/"
  }, "https://data-privacy-office.com/en/")), "\n", React.createElement(Address, {
    name: "KVKP",
    isoCountry: "tr"
  }), "\n", React.createElement(_components.p, null, "✉️ ", React.createElement(_components.a, {
    href: "mailto:bilgi@kvkp.org.tr"
  }, "bilgi@kvkp.org.tr")), "\n", React.createElement(_components.p, null, "🔗 ", React.createElement(_components.a, {
    href: "https://www.kisiselverilerinkorunmasi.org/"
  }, "https://www.kisiselverilerinkorunmasi.org/")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
